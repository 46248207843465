a {
  margin: 0.1rem;
}

button {
  margin: 0.1rem;
}

.btn {
  padding: 0em 0.75rem;
}
